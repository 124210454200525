import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate, CanMatch,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import {AuthService} from "@services/auth.service";

@Injectable({
  providedIn: 'root'
})

export class RoleGuard  implements CanActivate, CanMatch{
  constructor(private router: Router,
              private auth: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    return this.checkAccess(route);
  }

  canMatch(
    route: Route,
    segments: UrlSegment[]
  ): Promise<boolean | UrlTree> {
    return this.checkAccess(route);
  }

  private async checkAccess(route: Route | ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    await this.auth.waitForInit();
    const role = this.auth.role$.value;
    const grantedRoles = route.data? route.data['roles']: [];
    if (route.data && route.data['roles']?.includes(role)) {
      return true;  // Accès autorisé
    }

    console.warn('RoleGuard: '+role+ ' privileges needed: '+grantedRoles);
    console.warn('Redirecting to role landing page');
    return this.getRedirectUrl(role);
  }

  private getRedirectUrl(role: string | null | undefined): UrlTree {
    switch (role) {
      case 'admin':
        return this.router.createUrlTree(['/my-shop']);
      case 'reception':
        return this.router.createUrlTree(['/reservations']);
      case 'platform':
        return this.router.createUrlTree(['/quai']);
      case 'pre-registration':
        return this.router.createUrlTree(['/']);
      default: // role == null
        return this.router.createUrlTree(['/']);
    }
  }
}
