// auth.guard.ts
import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanMatch,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Route,
  UrlSegment
} from '@angular/router';
import {AuthService} from "@services/auth.service";
import {User} from "@angular/fire/auth";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanMatch {
  constructor(private authService: AuthService, private router: Router) {}

  canMatch(
    route: Route,
    segments: UrlSegment[]
  ): Promise<boolean | UrlTree> {
    return this.checkAccess(route);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    return this.checkAccess(route);
  }

  private async checkAccess(route: Route | ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    await this.authService.waitForInit();
    const user: User | null = this.authService.user$.value;
    if(user){
      return true;
    } else {
      console.warn('AuthGuard: Unauthenticated User');
      if(this.router.url != '/'){
        await this.router.navigate(['/'])
      }

      return false;
    }
  }
}
